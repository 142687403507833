import React from 'react';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';

import Logo from './VulpesGames_Logo.svg';
import Placeholder from './placeholder.svg';

const App: React.FC = () => {
  return (
    <>
      <Container className="pt-5 pb-5">
        <img
          src={Logo}
          height="60"
          alt="Vulpes Game"
          className="mx-auto"
          style={{
            display: "block"
          }}
        />
        <div className="mx-auto pt-5" style={{
            textAlign: "center"
          }}>
          <a className="github-button"
          href="https://github.com/vulpesgames"
          data-color-scheme="no-preference: light; light: light; dark: dark;"
          data-size="large"
          aria-label="Follow @vulpesgames on GitHub">
            Follow @vulpesgames
          </a>
        </div>
      </Container>
      { /* <Navbar expand="lg" bg="light" className="px-4" sticky="top" id="navbar">
        <Navbar.Brand>
          <img
          src={Logo}
          height="25"
          alt="Vulpes Game"
        />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#hello">ホーム</Nav.Link>
            <Nav.Link href="#about">概要</Nav.Link>
            <Nav.Link href="#products">製品</Nav.Link>
            <Nav.Link href="#contact">お問い合わせ</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Container className="my-3">
        <Jumbotron id="jumbotron">
          <h1>
            <span className="black-weight">WE ARE</span> Vulpes Games.
          </h1>
          <p>
            ゲーム開発者「むるえか」による同人サークルです。
          </p>
          <p>
            <Button variant="outline-primary">もっと詳しく</Button>
          </p>
        </Jumbotron>
        <Col>
          <div className="d-flex justify-content-center align-items-baseline">
            <span className="h2">Topics</span>
            <small className="px-2 text-muted">新着情報</small>
          </div>
        </Col>
        <Row>
        {
          [{
            id: 0,
            title: "ホームページ作成",
            date: "2019.10.13",
            content: "Vulpes Gamesホームページを作成いたしました。",
            link: "#",
          }].map((v, idx) => {
            return (
              <Col sm md={6} lg={4} className="my-3" key={`topics-${idx}`}>
                <Card>
                  <Card.Body>
                    <Card.Title>{v.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      <small>{v.date}</small>
                    </Card.Subtitle>
                    <Card.Text>
                      {v.content}
                    </Card.Text>
                    <Card.Link href={v.link}>もっと詳しく</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
            );
          })
        }
        </Row>
        <Col>
          <div className="d-flex justify-content-center align-items-baseline" id="about">
            <span className="h2">About</span>
            <small className="px-2 text-muted">概要</small>
          </div>
        </Col>
        <Row>
        {
          [{
            name: "むるえか（murueka）",
            info: "Vulpes Games代表。もふもふ。",
          }].map((v, idx) => {
            return (
              <>
                <Col xs={3} md={1} className="my-3" key={`topics-${idx}`}>
                  <img src="https://www.gravatar.com/avatar/9341278b0602470bf19637de9edca769?d=identicon"
                    width="100%"
                    className="rounded-circle" />
                </Col>
                <Col xs={9} md={11} className="my-3" key={`topics-${idx}`}>
                  <h5>{v.name}</h5>
                  <p>{v.info}</p>
                </Col>
              </>
            );
          })
        }
        </Row>
        <Col>
          <div className="d-flex justify-content-center align-items-baseline" id="products">
            <span className="h2">Products</span>
            <small className="px-2 text-muted">製品</small>
          </div>
        </Col>

        <Row>
        {
          [1, 2, 3].map((v) => {
            return (
              <Col sm md={6} lg={4} className="my-3" key={`products-${v}`}>
                <Card>
                  <Card.Img variant="top" src={Placeholder}/>
                  <Card.Body>
                    <Card.Title><b>NOW PRINTING</b></Card.Title>
                    <Card.Text>
                    -
                    </Card.Text>
                  </Card.Body>
                  <Card.Body>
                    {/*
                       <Card.Link href="#">もっと詳しく</Card.Link>
                    <Card.Link href="#">製品のウェブサイトへ</Card.Link>
                    * /}
                  </Card.Body>
                </Card>
              </Col>
            );
          })
        }
        </Row>

        <Col>
          <div className="d-flex justify-content-center align-items-baseline" id="contact">
            <span className="h2">Contact</span>
            <small className="px-2 text-muted">お問い合わせ</small>
          </div>
        </Col>
        
        <Card>
          <Card.Body>
            <Card.Title>フォーム</Card.Title>
            <Card.Text>
              お問い合わせは、以下のお問い合わせフォームからお願いします。（準備中）
            </Card.Text>
            <Button variant="primary">お問い合わせフォームへ</Button>
          </Card.Body>
        </Card>

      </Container> */}
      <footer className="footer py-3 fixed-bottom">
        <Container>
          <p className="text-muted d-flex justify-content-center">
            <small>
            Copyright © 2020 Vulpes Games.
            </small>
          </p>
        </Container>
      </footer>
    </>
  );
}

export default App;
